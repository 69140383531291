import {
	getWeixinTicket
} from '@/utils/api';
// let jweixin = require('jweixin-module');

import jweixin from 'weixin-js-sdk' // 引入微信SDK

export default {
	//判断是否在微信中  
	isWechat: function() {
		var ua = window.navigator.userAgent.toLowerCase();
		if (ua.match(/micromessenger/i) == 'micromessenger') {
			return true;
		} else {
			return false;
		}
	},

	//初始化sdk配置  
	initJssdkShare: function(institutionId,messageData, timelineData, callback, url) {
		url = url ? url : window.location.origin + window.location.pathname;
		if (!this.isWechat()) {
			return;
		}
		getWeixinTicket({
			url: encodeURI(url),
      institutionId:institutionId
		}).then(res => {
			if (res.data) {
				jweixin.config({
					debug: false,
					appId: res.data.appId,
					timestamp: res.data.timestamp,
					nonceStr: res.data.nonceStr,
					signature: res.data.signature,
					openTagList: ["wx-open-launch-weapp"],
					jsApiList: [
						"checkJsApi",
						"updateTimelineShareData",
						"updateAppMessageShareData",
					],
				});
				jweixin.ready(function() {
					//分享给朋友接口  
					jweixin.updateAppMessageShareData({
						title: messageData.title || '',
						desc: messageData.desc || '',
						link: messageData.link || '',
						imgUrl: encodeURI(messageData.imgUrl) || '',
					});
					//分享到朋友圈接口  
					jweixin.updateTimelineShareData({
						title: timelineData.title || '',
						link: timelineData.link,
						imgUrl: encodeURI(timelineData.imgUrl) || '',
					});
					if (callback) {
						callback()
					}
				});

			}
		})
	},
	//在需要自定义分享的页面中调用  
	share: function(signData, data) {
		//每次都需要重新初始化配置，才可以进行分享  
		jweixin.config({
			debug: false,
			appId: signData.appId,
			timestamp: signData.timestamp,
			nonceStr: signData.nonceStr,
			signature: signData.signature,
			openTagList: ["wx-open-launch-weapp"],
			jsApiList: [
				"checkJsApi",
				"updateTimelineShareData",
				"updateAppMessageShareData",
			],
		});
		jweixin.ready(function() {
			var shareData = {
				title: data && data.title ? data.title : "",
				desc: data && data.desc ? data.desc : "",
				link: data && data.url ? data.url : "",
				imgUrl: data && data.imgUrl ? encodeURI(data.imgUrl) : "",
				success: function() {
					//用户点击分享后的回调，这里可以进行统计 
				},
				cancel: function() {}
			};
			//分享给朋友接口  
			jweixin.updateAppMessageShareData(shareData);
			//分享到朋友圈接口  
			jweixin.updateTimelineShareData(shareData);
		});
	}
}
