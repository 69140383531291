<template>
  <div class="ml_sharesortlist">
    <div class="ml_time_box">{{ title }}</div>

    <LoadMore :onLoadMore="onLoadMore" :enableLoadMore="enableLoadMore">
      <div class="ml_list">
        <div class="ml_list_top">
          <div class="ml_number">排名</div>
          <div class="ml_info">姓名</div>
          <div class="ml_share_number">分享次数</div>
        </div>
        <div class="ml_lsit_item" v-for="(item, index) in orderList" :key="index">
          <div class="ml_list_item_number">{{ index+1 }}.</div>
          <div class="ml_list_item_info">
            <div class="ml_list_item_photo">
              <img v-if="item.avatarUrl" :src="item.avatarUrl" alt="" />
              <span v-if="!item.avatarUrl">{{item.username.slice(0,1)}}</span>
              <img v-if="index == 0" src="../../assets/47.png" class="ml_list_order_icon" alt="" />
              <img v-if="index == 1" src="../../assets/48.png" class="ml_list_order_icon" alt="" />
              <img v-if="index == 2" src="../../assets/49.png" class="ml_list_order_icon" alt="" />
            </div>
            <div class="ml_list_item_name">{{ item.username }}</div>
          </div>
          <div class="ml_list_item_share_number">{{item.shareCount}}</div>
        </div>
      </div>
    </LoadMore>
  </div>
</template>

<script>
import { getsharingRankings } from "@/utils/api";
import initJssdkShare from "@/common/wechat";

import LoadMore from "@/components/moredata";

export default {
  components: { LoadMore },
  data() {
    return {
      enableLoadMore: true,
      shareId: null,
      pageNum: 1,
      pageSize: 20,
      title: "",
      orderList: [],
      total:0,
      shareTitle:"",
      institutionId:null
    };
  },
  computed: {},
  mounted() {
    const that = this;
    that.shareId = that.$route.query.shareId;
    that.institutionId = that.$route.query.insId;
    that.getsharingRankings();  
  },
  methods: {
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.pageNum = this.pageNum + 1;
        this.getsharingRankings();
        done();
      }, 200);
    },
    getsharingRankings() {
      const that = this;
      getsharingRankings({
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        shareId:that.shareId,
        insId:that.institutionId
      }).then((res) => {

        if (that.pageNum == 1) {

          that.title = "统计时间 "+res.extraInfo.beginDate+' - '+res.extraInfo.endDate+'('+res.extraInfo.granularity+')';
          that.shareTitle = res.extraInfo.granularity+ ' ('+res.extraInfo.beginDate+' - '+res.extraInfo.endDate+')'+' 分享排行榜';

          initJssdkShare.initJssdkShare(
            that.institutionId,{
            title:that.shareTitle,
            desrc:"加油分享，潜客多多",
            link:window.location.href,
            imgUrl:"http://carwins-images.oss-cn-hangzhou.aliyuncs.com/erp-saas-manage-static/share_ranking.png"
          },{
            title: that.shareTitle,
            link: window.location.href,
            imgUrl: "http://carwins-images.oss-cn-hangzhou.aliyuncs.com/erp-saas-manage-static/share_ranking.png"
          })

        }

        that.orderList = [...that.orderList,...res.data.list]
        that.total = res.data.total;

        if (that.total <= that.orderList.length) {
          that.enableLoadMore = false;
        }

      });
    },
  },
};
</script>
<style lang='scss'>
.ml_sharesortlist {
  .ml_time_box {
    padding-left: 10px;
    box-sizing: border-box;
    background: #f5f7f9;
    height: 40px;
    font-size: 12px;
    color: #979797;
    line-height: 40px;
  }
  .ml_list {
    background: #ffffff;
    box-sizing: border-box;
    padding: 0 10x;
    .ml_list_top {
      margin-top: 15px;
      padding: 0 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      color: #979797;
      .ml_number {
        width: 55px;
      }
      .ml_info {
        width: calc(100% - 135px);
      }
      .ml_share_number {
        width: 80px;
        text-align: right;
      }
    }
    .ml_lsit_item {
      margin-top: 20px;
      padding: 0 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      .ml_list_item_number {
        width: 55px;
        font-family: "number_iconfont" !important;
        font-size: 16px;
        color: #292D33;
      }

      .ml_list_item_info {
        width: calc(100% - 135px);
        display: flex;
        align-items: center;
        .ml_list_item_photo {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background: #327fff;
          text-align: center;
          line-height: 44px;
          color: #ffffff;
          font-size: 18px;
          position: relative;
          img {
            width: 44px;
            height: 44px;
            border-radius: 50%;
          }
          .ml_list_order_icon {
            position: absolute;
            top: -12px;
            left: -7px;
            width: 22px;
            height: 22px;
          }
        }
        .ml_list_item_name{
          font-size: 14px;
          color: #292D33;
          font-weight: 600;
          margin-left: 10px;
        }
      }

      &:nth-child(2) {
        .ml_list_item_number{
          color: #FEAE30;
        }
        .ml_list_item_info .ml_list_item_photo{
          border: 2px solid #feae30;
        }
      }
      &:nth-child(3) {
        .ml_list_item_number{
          color: #CDCED0;
        }
        .ml_list_item_info .ml_list_item_photo{
          border: 2px solid #cdced0;
        }
      }
      &:nth-child(4) {
        .ml_list_item_number{
          color: #ED8246;
        }
        .ml_list_item_info .ml_list_item_photo{
          border: 2px solid #ed8246;
        }
      }

      .ml_list_item_share_number {
        width: 80px;
        text-align: right;
        font-size: 14px;
        color: #292d33;
        font-weight: 600;
      }
    }
  }
}
</style>